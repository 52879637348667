import { TagIcon } from '@/assets/icons';
import { GetAllTagsQuery } from '@/generated/graphql';
// import { tags } from '@/services/mock/tag';
import Link from 'next/link';
import { useMemo } from 'react';
interface ITagWrapper {
  title?: string;
  data?: GetAllTagsQuery;
  notFullTag?: boolean;
}
const TagWrapper = ({ title, data, notFullTag = false }: ITagWrapper) => {
  const listTag = useMemo(
    () => (notFullTag ? data?.tags?.slice(3) : data?.tags),
    [data?.tags, notFullTag],
  );

  return (
    <div
      className={`flex flex-col gap-4 ${
        title && 'border-t-[1px] border-b-[1px] py-4 border-[#3F4D5C]'
      }`}
    >
      {title && (
        <div className="flex gap-2 items-center">
          <TagIcon />
          <p className="text-black font-bold">{title}</p>
        </div>
      )}
      <div className="flex flex-wrap gap-4">
        {listTag?.map((tag, index) => {
          return (
            <Link
              href={`/tim-kiem?keyword=${tag.name}`}
              key={index}
              className="group"
            >
              <h2 className="text-xs font-medium text-DarkBlue px-4 py-2 bg-Silver rounded-md group-hover:opacity-80">
                {tag.name}
              </h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default TagWrapper;
